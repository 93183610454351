<template>
  <iframe :src="iframeSrc"></iframe>
</template>

<script>
export default {
  data() {
    return {
      iframeSrc: 'https://share.tcbi.qq.com/page/share?pageId=10537615&projectId=11025336&token=9961d23c-0efe-4d85-955a-cb147e73f935&scope=page&canvasType=GRID' // 替换为你想要嵌入的网站URL
    };
  }
};
</script>

<style>
/* 可以添加一些基本样式 */
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>